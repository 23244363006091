const loadFormHTML = function(addUrl, dialog, preDefinedFormData) {
    const dialogBody = dialog.find('.modal-body');

    dialog.find('[data-bb-handler="save"]').prop('disabled', 'disabled');

    $.ajax({
        url: addUrl,
        type: "GET",
        dataType: "json",
        success: function (response) {
            dialogBody.html(response.content);
            dialog.find('[data-bb-handler="save"]').prop('disabled', '');

            if(typeof preDefinedFormData !== "undefined" && preDefinedFormData !== null && preDefinedFormData !== {}) {
                for (const [key, value] of Object.entries(preDefinedFormData)) {
                    const field = dialogBody.find(`[name$="[${key}]"]`);
                    if (field !== "undefined" && field !== "") {
                        field.val(value);
                    }
                }
            }

            // delayed to avoid loosing focus.
            setTimeout(() => dialog.find('input:first').focus(), 400)

        },
        error: function (response) {
            dialogBody.html('Възникна грешка при зареждането на формата! Моля опитайте отново.');
        }
    });
}

const partnerInitFunc = function (element, callback) {
    element = $(element);
    var partnerId = element.attr('data-partner-id');
    var partnerName = element.attr('data-partner-name');

    if (typeof partnerId !== "undefined" && partnerId !== "" && typeof partnerName !== "undefined" && partnerName !== "") {
        //console.log("Partner init func: ", partnerId,partnerName);
        callback({id: partnerId, label: partnerName})
    }
};

const partnerBulstatInitFunc = function (element, callback) {
    var partnerBulstat = $(element).attr('data-bulstat');

    if (typeof partnerBulstat !== "undefined" && partnerBulstat !== "") {
        callback({id: partnerBulstat, label: partnerBulstat})
    }
};


// Usage:
// adimeks.components.partners.createModal(
//     "{{ path("partners_ajax_add") }}",
//      "Client name" || null,
//      123456 || null
//  ).then((response) => {
//      console.log(`Success response is ${response}`);
//  }).catch((error) => {
//      // when error is NULL then the dialog was "cancelled"; on error this will not be NULL and will contain the error
//      // response.
//      console.log(`Error response is ${error}`)
//  });
module.exports.createModal = function (addUrl, preDefinedFormData) {

    return new Promise((resolve, reject) => {

        // 1. Creates initial modal form with a loading message
        const dialog = bootbox.dialog({
            title: 'Управление на контрагент',
            message: adimeks.messages.loading,
            buttons: {
                save: {
                    label: "Запази",
                    className: "btn-success",
                    callback: function () {
                        var dialogBody = dialog.find('.modal-body');

                        var form = dialog.find('form').serialize();

                        dialogBody.html(adimeks.messages.loading);

                        dialog.find('.modal-footer button').prop('disabled', 'disabled');

                        $.ajax({
                            url: addUrl,
                            type: "POST",
                            dataType: "json",
                            data: form,
                            success: function (response) {
                                if (response.status === true) {
                                    if (typeof saveCallback !== "undefined") {
                                        saveCallback(response, dialog);
                                    }
                                    dialog.modal('hide');
                                    resolve(response)
                                } else {
                                    dialogBody.html(response.content);
                                    dialog.find('.modal-footer button').prop('disabled', '');
                                }
                                dialog.find('.model-footer button').prop('disabled', '');
                            },
                            errors: function (response) {
                                dialogBody.html('Възникна грешка при обработката на заявката ви! Моля опитайте отново.');
                                reject(response);
                            }
                        });

                        return false;
                    }
                },
                cancel: {
                    label: "Откажи",
                    className: "btn-danger",
                    callback: function () {
                        if (typeof cancelCallback !== "undefined") {
                            cancelCallback()
                        }
                        reject(null)
                    }
                }
            }
        });

        // 2. Fetches the form HTML and then appends it to the modal window.
        loadFormHTML(addUrl, dialog, preDefinedFormData);

    });
}


module.exports.partnerInitFunc = partnerInitFunc;

module.exports.partnerBulstatInitFunc = partnerBulstatInitFunc;