const bootbox = require('bootbox');
const messages = require('../messages.json');

/**
 * Generic delete modal with ajax.
 *
 * @param url
 * @param element
 */
module.exports = function (url, element, refresh, data) {

    return new Promise((resolve, reject) => {
        let row = null;
        if(element !== undefined && element !== null && element !== "")
        {
            row = element.closest('tr');
        }
        if(refresh === undefined) {
            refresh = false;
        }
        if(data === undefined) {
            data = {};
        }

        const dialog = bootbox.dialog({
            title: 'Потвърждение',
            message: 'Сигурни ли сте, че искате да изтриете този запис?',
            buttons: {
                success: {
                    label: 'Да, изтрий го!',
                    className: 'btn-danger',
                    callback: function () {
                        const dialogBody = dialog.find('.modal-body');

                        dialogBody.html();
                        dialog.find('[data-bb-handler]').prop('disabled', 'disabled');

                        // for cases when there is no need for URL
                        if(url !== null && url !== undefined) {
                            $.ajax({
                                url: url,
                                type: 'DELETE',
                                dataType: 'json',
                                data: data,
                                success: function (response) {
                                    dialog.find('.modal-body').html(messages.successful_delete);

                                    if (element !== null && row !== null) {
                                        row.fadeOut(function () {
                                            $(this).remove();
                                            if (refresh !== undefined && refresh === true) {
                                                location.reload();
                                            }
                                        })
                                    }

                                    setTimeout(function () {
                                        dialog.modal('hide');
                                        resolve(response);
                                    }, 1600);
                                },
                                error: function (response) {
                                    dialog.find('.modal-body').html(messages.failed_to_save);
                                    dialog.find('[data-bb-handler="close"]').prop('enable', 'enable');
                                    reject(response);
                                }
                            });

                            return false;
                        }
                        else
                        {
                            row.fadeOut(() => $(this).remove());
                            resolve(null);
                        }
                    }
                },
                close: {
                    label: 'Откажи',
                    className: 'btn-primary',
                    callback: function() {
                        reject(null);
                    }
                }
            }
        });
    }).catch((error) => {
        if(error != null) {
            return error
        }
    });

}
