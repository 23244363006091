// This exported object is available as `adimeks.boot`, `adimeks.**`
module.exports = {
    boot: require('./common/boot'),
    messages: require('./messages'),
    genericSearch: require('./common/genericSearch'),
    deleteRecord: require('./common/deleteRecord'),
    select2: require('./common/select2'),
    assignSelect2: require('./common/select2').assignSelect2,
    filesize: require('filesize'),
    inputValueLimiter: require('./common/inputValueLimiter'),
    components: {
        partners: require('./components/partners')
    }
};

