module.exports = function (inputSelector, pattern) {

    if(typeof inputSelector == 'string') {
        element = $(inputSelector);
    } else {
        element = inputSelector;
    }

    element.on('keypress', function(e) {
        // Do not allow event propagation when the entered character does not match the pattern.
        e.stopImmediatePropagation();
        e.stopPropagation();

        // Allow CTRL & Alt keys
        if (e.ctrlKey !== true && e.altKey !== true) {
            const matches = e.key.match(pattern) !== null;
            if(!matches) {
                e.preventDefault();
            }
            return matches;
        }
    });

}