/**
 * This is a generic search javascript function
 *
 * @param opts
 */
module.exports = function (opts) {

    const { formSelector, searchUrl } = opts;

    const form = $(formSelector);
    const filter = form.find('div.search-filter:first');
    const searchFilter = form.find('[name="search_filter"]');

    if(filter !== "") {
        filter.find('.dropdown-menu a').click(function(e) {
            e.preventDefault();
            const param = $(this).attr("href").replace("#","");
            const selectedFilter = $(this).text();
            filter.find('.current-filter').text(selectedFilter);
            searchFilter.val(param);
        });
    }

    form.on('submit', function (e) {
        e.preventDefault();

        const term = $(this).find('input[type=text]:first').val().trim();

        let link = searchUrl.replace(/\/+$/, '') + "/search";

        if(filter !== "" && searchFilter !== "" && searchFilter.length > 0 && searchFilter.val().trim() !== "") {
            link += "/" + encodeURIComponent('' + searchFilter.val().trim());
        }

        if (!/^\s*$/.test(term)) {
            link += '/' + encodeURIComponent('' + term);
        }

        window.location.href = link;
    });
};
