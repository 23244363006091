export function formatNoMatches() { return "Няма намерени резултати"; }
export function formatInputTooShort(input, min) { let n = min - input.length; return "Моля въведете "+(input.length > 0 ? 'още ' : '') + n + " символ" + (n === 1? "" : "а"); }
export function formatLoadMore(pageNumber) { return "Зареждане..."; }
export function formatSearching() { return "Търсене..."; }

export function initFunctionForTags(element, callback) {
    element = $(element);
    var value = element.val();
    if (value !== "") {
        let tags = value.split(",,,,,")
            .map(function (v) {
                return v.split(/([0-9]+):/g)
            })
            .map(function(v) {
                if (v[0] === "") v.shift();
                return v;
            })
            .map(function(v) {
                return {
                    id: v[0],
                    label: v[1]
                }
            })
        ;
        element.val("");
        callback(tags);
    }
}

/**
 * Assign a select2 (version 3) to an element.
 *
 * @param element = the element.
 * @param options = custom wrapper options which speed-up creating select2 fields.
 * @param rawSelect2options = raw select2 options
 *
 */
export function assignSelect2(element, options, rawSelect2options = {}) {
    // minimumLength, searchSource, searchOptions, initFunction, createSearchChoice, formatField, formatSelection
    let defaultOptions = {
        minimumInputLength: 1,

        formatField: false,
        formatSelection: false,

        allowClear: false,
        searchSource: null,
        searchOptions: {
            quietMillis: 200,
            dataType: 'json',
            data: function (term, page) {
                return {
                    term: term, // search term,
                    page: page,
                    style: 'select2'
                };
            },
            results: function (data, page) { // parse the results into the format expected by Select2.
                let more = (page * 10) < data.total; // whether or not there are more results available

                // notice we return the value of more so Select2 knows if more results can be loaded
                return {results: data.results, page: page, more: more};
            }
        },

        initFunction: function(element, callback) {
            element = $(element);
            let value = element.val();
            if(value !== "") {
                callback(value);
            }
        }
    };

    if(typeof options != "undefined" && typeof options == "object")
        options = $.extend(defaultOptions, options);
    else
        options = defaultOptions;

    let defaultSelect2Options = {
        formatNoMatches    : formatNoMatches,
        formatInputTooShort: formatInputTooShort,
        formatLoadMore     : formatLoadMore,
        formatSearching    : formatSearching,

        // Select currently highlighted option if you click outside.
        // NOTE: Might not work as expected in some business logic cases therefor should be false.
        selectOnBlur: false,

        formatSelection: function(result) {
            let label = result.text; // default select2 object -- what you will receive when using <select></select>
            if(result.hasOwnProperty('label')) {
                // what we receive from our API.
                label = result.label;
            }

            let field = false;
            if(options.formatSelection && !options.formatSelection)
                field = options.formatSelection;
            else if(!options.formatSelection && options.formatSelection)
                field = options.formatSelection;

            if(field){
                if(typeof field == "string")
                    label = eval(field);
                else if(typeof field == "object") {
                    label = '';
                    $.each(field,function(key,value){
                        if(value === ":")
                            label += ": ";
                        else
                            label += result[field]+" ";
                    });
                }
            }

            return label;

            //return result.label;
        },
        formatResult: function(result, container, query, escapeMarkup) {

            let label = result.text; // default select2 object -- what you will receive when using <select></select>
            if(result.hasOwnProperty('label')) {
                // what we receive from our API.
                label = result.label;
            }

            if(options.formatField){
                if(typeof options.formatField == "string")
                    label = eval(options.formatField);
                else if(typeof options.formatField == "object") {
                    label = '';
                    $.each(options.formatField,function(key,field){
                        if(field === ":")
                            label += ": ";
                        else
                            label += " "+result[field];
                    });

                    label = $.trim(label);
                }
            }

            return "<table class='movie-result' style='width: 100%'>" +
                "<tr>" +
                "<td class='movie-info'>" +
                "<div class='movie-title'>" + label + "</div>" +
                "</td>" +
                "</tr>" +
                "</table>";
        },
        escapeMarkup: function (m) { return m; } // we do not want to escape markup since we are displaying html in results
    };

    if(!element.is('select') && (typeof options.initFunction != "undefined" && typeof options.initFunction == "function"))
    {
        defaultSelect2Options = $.extend(defaultSelect2Options, {initSelection: options.initFunction});
    }

    if(typeof options.searchSource != "undefined" && options.searchSource != null)
    {
        defaultSelect2Options.ajax = {
            url: options.searchSource
        };

        defaultSelect2Options.ajax = $.extend(options.searchOptions, defaultSelect2Options.ajax);
    }

    if(!element.is('select') && typeof options.createSearchChoice != "undefined" )
    {
        if(typeof options.createSearchChoice == "boolean" && options.createSearchChoice)
        {
            defaultSelect2Options.createSearchChoice = function( term, data ) {
                if ( $(data).filter(function() {
                    return this.label.localeCompare(term) === 0;
                }).length === 0 ) {
                    return {
                        id   : -Math.floor(Math.random() * 100000000000000001),
                        label: term + ' (Създай)'
                    };
                }
            }
        }
        else if(typeof options.createSearchChoice == "function")
        {
            defaultSelect2Options.createSearchChoice = options.createSearchChoice;
        }
        else
        {
            defaultSelect2Options.createSearchChoice = null;
        }
    }

    let finalOptions = {};

    if(typeof finalOptions != "undefined" && typeof finalOptions == "object")
        finalOptions = $.extend(defaultSelect2Options, finalOptions);
    else
        finalOptions = defaultSelect2Options;

    finalOptions = $.extend(finalOptions, rawSelect2options);

    //console.log('Element: ', element);
    //console.log('Settings', finalOptions)

    element.select2(finalOptions);

    return element;
}
