const moment = require('moment');

// Private functions (not exported)
function _startClock(serverTime) {
    let time = moment(serverTime, 'x');
    time.locale('bg');

    const element = $('#time');
    element.html(time.format('HH:mm:ss DD.MM.YYYY <br> dddd'));

    setInterval(() => {
        time = time.add(1, 'seconds');
        element.html(time.format('HH:mm:ss DD.MM.YYYY <br> dddd'))
    }, 999);
}

function _bootstrapComponents() {
    // Dropdown menus
    $('ul.dropdown-menu [data-toggle=dropdown]').on('click', function (event) {
        // Avoid following the href location when clicking
        event.preventDefault();
        // Avoid having the menu to close when clicking
        event.stopPropagation();
        // If a menu is already open we close it
        $('ul.dropdown-menu [data-toggle=dropdown]').parent().removeClass('open');
        // opening the one you clicked on
        $(this).parent().addClass('open');
    });

    // Help popups.
    $('[data-toggle="popover"]').popover({'trigger': 'hover'});
}

let booted = false;

// Export a public function which will be used when booting `adimeks.boot()`
module.exports = function (serverTime) {
    if (!booted) {
        booted = true;
        console.log('= Booting (server time %s)', serverTime);
        _startClock(serverTime);
        _bootstrapComponents();
        console.log('= Booted');
    } else {
        console.log('Already booted.');
    }
};
